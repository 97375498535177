let connectName = process.env.REACT_APP_CONNECT_NAME || "<CONNECT_NAME>";
let apiGatewayID = process.env.REACT_APP_API_GATEWAY_ID || "<API_GATEWAY_ID>";
let agentApiGatewayID =
  process.env.REACT_APP_AGENT_API_GATEWAY_ID || "<API_GATEWAY_ID>";
let cfDistributionUrl = "<CF_DISTRIBUTION_URL>";
let surveyUrl = process.env.REACT_APP_SURVEY_ENDPOINT;

// set default values for dev
export const CONNECT_NAME = connectName.includes("<")
  ? "company-name-connect-dev"
  : connectName;
export const API_GATEWAY_ID = apiGatewayID.includes("<")
  ? "<<API_GATEWAY_ID>>"
  : apiGatewayID;
export const AGENT_API_GATEWAY_ID = apiGatewayID.includes("<")
  ? "<<API_GATEWAY_ID>>"
  : agentApiGatewayID;
export const CF_DISTRIBUTION_URL = cfDistributionUrl.includes("<")
  ? "<<CF_DISTRIBUTION_URL>>"
  : cfDistributionUrl;

export const API_HOST = `https://${API_GATEWAY_ID}.execute-api.us-east-1.amazonaws.com/prod`;
export const AGENT_API_HOST = `https://${AGENT_API_GATEWAY_ID}.execute-api.us-east-1.amazonaws.com`;

export const POST_CALL_INTENT_URL = `${API_HOST}/postNewCallIntent`;
export const buildIntentsForAgentURL = (agentName) => {
  return `${API_HOST}/getIntentsForAgent?agentName=${agentName}`;
};
export const UPDATE_RECORDING_STATUS_URL = `${API_HOST}/updateRecordingStatus`;

export const buildRecordAgentActivityURL = () =>
  `${AGENT_API_HOST}/record-agent-activity`;

export const buildGetDispositionsURL = (queueName) =>
  `${API_HOST}/dispositions/${queueName}`;
export const buildGetBlueprintsUrl = (queueName) =>
  `${API_HOST}/blueprints/${queueName}`;
export const buildSendSurveyURL = () => `${surveyUrl}/trigger`;
export const POST_NEW_CONTACT_ID_URL = ({agentName, id}) =>
  `${API_HOST}/postNewContactId?agentName=${agentName}&connectID=${id}`;
