import "amazon-connect-streams";
import React, {useCallback, useEffect, useState} from "react";
import Text from "aws-northstar/components/Text";
import Box from "aws-northstar/layouts/Box";
import Container from "aws-northstar/layouts/Container";
import ColumnLayout, {Column} from "aws-northstar/layouts/ColumnLayout";
import Stack from "aws-northstar/layouts/Stack";
import {
  useConnected,
  useDestroy,
  useConnecting,
  useCallCompleted,
} from "../hooks";
import {genLogger, valueToOption} from "../lib";
import ContactDispositionSection from "./ContactDispositionSection";
import ActionsSection from "./ActionsSection";
import {
  buildGetBlueprintsUrl,
  buildGetDispositionsURL,
  buildRecordAgentActivityURL,
} from "../config";
import ContactDetails from "./ContactDetails";
import DispositionModalTailwind from "./DispositionModalTailwind";
import Checklist from "./Checklist";
import {CDLPreviewFetch} from "../hooks";

const name = "CustomerInfo";
const {log, error} = genLogger(name);

const handleAgentLogout = () => {
  window.connect.agent((agent) => {
    agent.setState(window.connect.AgentStateType.OFFLINE);
  });
};

const handlePreviewFetch = async (number) => {
  let newNumber = number.replace(/\+/g, "");
  try {
    let data = await CDLPreviewFetch(newNumber);
    console.log("[handlePreviewFetch] data", data);
    return data;
  } catch (e) {
    console.log("CDLPreviewFetch error", e);
  }
};

const formatData = async (attr = {}) => {
  attr.contactId = attr?.contactId || null;
  attr.customerNumber = attr?.connections[1]?.endpoint?.phoneNumber || null;
  attr.queueName = attr?.queue?.name || null;
  attr.channel = attr?.type || null;
  attr.url = attr?.attributes?.attributes_URL?.value || null;
  attr.label = attr?.attributes?.attributes_Label?.value || null;
  attr.outboundStatus = attr?.attributes?.CPA?.value || null;
  attr.memberNumber = attr?.attributes?.MemberNumber?.value || null;
  attr.endpointId = attr?.attributes?.endpointId?.value || null;
  attr.applicationId = attr?.attributes?.applicationId?.value || null;

  if (
    process.env.REACT_APP_CONNECT_NAME === "icx-cdl" &&
    attr?.label == null &&
    attr?.url == null
  ) {
    let res = await handlePreviewFetch(
      attr?.connections[1]?.endpoint?.phoneNumber
    );
    if (res) {
      if (res.Label) attr.label = res.Label;
      if (res.URL) attr.url = res.URL;
    } else {
      attr.label = null;
      attr.url = null;
    }
  }
  console.log(
    "Customer Number =====>",
    attr?.connections[1]?.endpoint?.phoneNumber
  );
  return {
    outboundStatus: attr?.outboundStatus,
    contactId: attr?.contactId,
    customerNumber: attr?.customerNumber,
    queueName: attr?.queueName,
    channel: attr?.channel,
    url: attr?.url,
    label: attr?.label,
    memberNumber: attr?.memberNumber,
    endpointId: attr?.endpointId,
    applicationId: attr?.applicationId,
  };
};

const keyNameMappings = {
  outboundStatus: "Outbound Status",
  contactId: "Contact ID",
  customerNumber: "Customer Number",
  queueName: "Queue Name",
  channel: "Channel",
  url: "URL",
  label: "Label",
  memberNumber: "Member Number",
  endpointId: "Endpoint ID",
  applicationId: "Application ID",
};

const getDispositions = (queueName) => {
  const dispositionURL = buildGetDispositionsURL(queueName);
  return fetch(dispositionURL).then((res) => res.json());
};

const recordAgentActivity = (agentName, contactId) => {
  try {
    const agentActivityURL = buildRecordAgentActivityURL();
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({agentName, contactId}),
    };

    return fetch(agentActivityURL, options)
      .then((res) => res.json())
      .catch((err) => {
        console.log(`error in adding agent activity`);
      });
  } catch (error) {
    console.log(`error in adding agent activity`);
  }
};

const initialData = {};

const CustomerInfo = () => {
  const [initialDisposition, setInitialDisposition] = useState({
    value: "-",
    label: "Select a disposition",
  });
  const [selectedDisposition, setSelectedDisposition] = useState({
    value: "-",
    label: "Select a disposition",
  });
  const [callDispositions, setCallDispositions] = useState([]);
  const [agentName, setAgentName] = useState(null);
  const [blueprints, setBlueprints] = useState([]);
  const [data, setData] = useState(initialData);

  useEffect(() => {
    const onBeforeUnload = (event) => {
      handleAgentLogout();
    };

    window.addEventListener("beforeunload", onBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);

  const onConnected = useCallback(async (c) => {
    try {
      const attr = c._getData();
      if (attr === null) {
        throw new Error("attr was null");
      }

      const d = await formatData(attr);
      console.log(
        `Formatted Attributes [On Connected] ======> ${JSON.stringify(d)}`
      );
      if (Object.keys(d).length > 0) {
        log("setting data...", d);
        setData(d);
        const {disposition} = d;
        setInitialDisposition(valueToOption(disposition));
      }
    } catch (e) {
      error("couldn't set data", e);
    }
  }, []);
  useConnected(onConnected);

  const onConnecting = useCallback(async (c) => {
    try {
      const attr = c._getData();
      window.connect.agent(async (agent) => {
        let config = agent.getConfiguration();
        let agent_name = config.name;
        let response = await recordAgentActivity(agent_name, attr.contactId);
        console.log(
          `Agent Activity Response [On Connecting] ========> ${JSON.stringify(
            response
          )}`
        );
        setAgentName(agent_name);
      });
      window.connect.contact((e) => {
        console.log("Contact Id [On Connecting] ========>", e);
      });

      if (attr === null) {
        throw new Error("attr was null");
      }

      const d = await formatData(attr);
      console.log(
        `Formatted Attributes [On Connecting] ======> ${JSON.stringify(d)}`
      );
      if (Object.keys(d).length > 0) {
        log("setting data...", d);
        setData(d);
        const {disposition} = d;
        setInitialDisposition(valueToOption(disposition));
      }
    } catch (e) {
      error("couldn't set data", e);
    }
  }, []);
  useConnecting(onConnecting);

  const onDestroy = useCallback(() => {
    try {
      log("destroyed, emptying data");
      setData(initialData);
      setSelectedDisposition({});
      setInitialDisposition({});
    } catch (e) {
      error("couldn't empty data", e);
    }
  }, []);
  useDestroy(onDestroy);

  useEffect(() => {
    let isCancelled = false;
    const asyncFunc = async () => {
      try {
        if (data?.queueName && data?.queueName !== "-") {
          const response = await getDispositions(data?.queueName);
          const parsed = JSON.parse(response.body);
          const dispositionList = parsed.dispositions;
          const formattedDispositions = dispositionList
            .map((disposition) => valueToOption(disposition))
            .sort((a, b) => a.label.localeCompare(b.label));
          console.log(
            `Dispositions [On Data] ======> ${JSON.stringify(
              formattedDispositions
            )}`
          );
          setCallDispositions(formattedDispositions);
        }
      } catch (e) {
        if (!isCancelled) error(e);
      }
    };
    asyncFunc();
    return () => (isCancelled = true);
  }, [data]);

  return (
    <div>
      <ContactDetails
        contactData={data}
        keyNameMappings={keyNameMappings}
        agentName={agentName}
      />
      <DispositionModalTailwind
        initialDisposition={initialDisposition}
        selectedDisposition={selectedDisposition}
        setSelectedDisposition={setSelectedDisposition}
        callDispositions={callDispositions}
        queueName={data?.queueName}
        contactId={data?.contactId}
        number={data?.customerNumber}
        agentName={agentName}
        endpointId={data?.endpointId}
        applicationId={data?.applicationId}
        label={data?.label}
        url={data?.url}
        data={data}
      />
      <Checklist queueName={data?.queueName} />
    </div>
  );
};

export default CustomerInfo;
