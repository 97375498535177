import React, { useCallback, useMemo } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AppLayout from "./components/AppLayout";
import Dashboard from "./components/Dashboard";
import "./index.css";

const withLayout = (Component) => (props) => {
  const Comp = useCallback(() => <Component {...props} />, [props]);
  return (
    <AppLayout>
      <Comp />
    </AppLayout>
  );
};

const App = () => {
  const Dash = useMemo(() => withLayout(Dashboard), []);

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Dash} />
      </Switch>
    </Router>
  );
};

export default App;
