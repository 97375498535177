import "amazon-connect-streams";
import React, { memo, useRef, useEffect, useState } from "react";
import { CONNECT_NAME } from "../config";
import { genLogger } from "../lib";

const name = "ConnectCCP";
const { log, error } = genLogger(name);

const ConnectCCP = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const ref = useRef();

  useEffect(() => {
    try {
      log("init start");
      if (typeof window === "undefined") throw new Error("window missing");
      if (typeof window.connect === "undefined")
        throw new Error("global connect missing");
      window.connect.getLog().setEchoLevel(window.connect.LogLevel.ERROR);
      window.connect.core.initCCP(ref.current, {
        ccpUrl: `https://${CONNECT_NAME}.my.connect.aws/connect/ccp-v2`,
        loginPopup: true,
        loginPopupAutoClose: true,
        loginOptions: {
          autoClose: true,
        },
        pageOptions: {
          enableAudioDeviceSettings: true,
          enablePhoneTypeSettings: true,
        },
        softphone: { allowFramedSoftphone: true },
      });
      log("init end");
    } catch (e) {
      error(e);
    }
  }, [ref]);

  // log("render");
  return (
    <div
      ref={ref}
      style={{
        width: "100%",
        height: "100%",
        minHeight: 400,
        minWidth: 380,
        border: "1px solid #4a4a4a",
      }}
    />
  );
};

export default memo(ConnectCCP);
