import {Dialog, Listbox, Transition} from "@headlessui/react";
import {CheckIcon, ChevronUpDownIcon} from "@heroicons/react/24/solid";
import {AwsClient} from "aws4fetch";
import React, {Fragment, useCallback, useEffect, useState} from "react";
import {useCallCompleted} from "../hooks";
import {genLogger, valueToOption} from "../lib";
import getClientSms from "../sms";
import axios from "axios";
import {buildSendSurveyURL} from "../config";
import {
  ConnectClient,
  UpdateContactAttributesCommand,
} from "@aws-sdk/client-connect";
const client = new ConnectClient({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  },
});
const APP_NAME = process.env.REACT_APP_CONNECT_NAME;

const aws = new AwsClient({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const name = "DispositionModal";
const {log, warn} = genLogger(name);

export default function DispositionModalTailwind({
  initialDisposition,
  selectedDisposition,
  setSelectedDisposition,
  callDispositions,
  queueName,
  contactId,
  number,
  agentName,
  endpointId,
  applicationId,
  label,
  url,
  data,
}) {
  const [survey, setSurvey] = useState({});
  const [visible, setVisible] = useState(false);
  const [mainDisposition, setMainDisposition] = useState({
    value: "-",
    label: "-",
  });
  const [dispositions, setDispositions] = useState([]);
  const [reportingId, setReportingId] = useState(null);

  const handleCreateUploadEntry = async () => {
    var options = {
      method: "POST",
      url: `${process.env.REACT_APP_MEDCALL_URL}/add`,
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_MEDCALL_API_KEY,
      },
      data: {callId: contactId, reportId: reportingId},
    };
    axios
      .request(options)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.error(error);
      });
    // const url = `${medcall_url}/add`;

    // console.log("[handleCreateUploadEntry]: ", url, medcall_key);

    // const data = {
    //   initialContactId: initialContactId,
    //   reportingId: reportingId,
    // };

    // const headers = {
    //   "Content-Type": "application/json",
    //   "x-api-key": medcall_key,
    // };

    // let response = await axios.post(url, data, headers);
    // console.log("[handleCreateUploadEntry]: ", response);
  };

  useEffect(() => {
    if (callDispositions.length > 0) setDispositions(callDispositions);
    if (typeof selectedDisposition === "string")
      setMainDisposition(valueToOption(selectedDisposition));
  }, [selectedDisposition, callDispositions]);

  useEffect(() => {
    if (queueName) {
      setSurvey((survey) => ({
        queueName,
        contactId,
        number,
      }));
    }
  }, [queueName]);

  useEffect(() => {
    if (agentName) {
      setSurvey((survey) => ({
        ...survey,
        agentName,
      }));
    }
  }, [agentName]);

  const onCallCompleted = useCallback(
    (c) => {
      setVisible(true);
    },
    [selectedDisposition]
  );
  useCallCompleted(onCallCompleted);

  const onInputChange = useCallback(
    (disposition) => {
      setSelectedDisposition(disposition);
      setMainDisposition(disposition);
    },
    [setSelectedDisposition, setMainDisposition]
  );

  const sendSurvey = async (data) => {
    const surveyURL = buildSendSurveyURL();

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    console.log("options", options);
    return await fetch(surveyURL, options).then((res) => res.json());
  };

  async function postContactAttributesWithCredentials(initialContactId) {
    const input = {
      InitialContactId: initialContactId,
      InstanceId: process.env.REACT_APP_CONNECT_INSTANCE_ID,
      Attributes: {
        disposition: mainDisposition.value ? mainDisposition.value : "NA",
        label: label ? label : "NA",
        url: url ? url : "NA",
      },
    };

    try {
      const command = new UpdateContactAttributesCommand(input);
      const response = await client.send(command);

      return response;
    } catch (error) {
      console.error("Request failed with error:", error);
    }
  }

  async function updateEndpoint() {
    const url = `https://pinpoint.us-east-1.amazonaws.com/v1/apps/${applicationId}/endpoints/${endpointId}`;
    console.log("[updateEndpoint]: ", url);
    const data = {
      Attributes: {
        Status: ["Completed"],
      },
    };

    try {
      const response = await aws.fetch(url, {
        method: "PUT",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`);
      }

      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error("Request failed with error:", error);
    }
  }

  const ccpSurveyQueues = [
    {
      queueName: "MMA_MBR_English",
      language: "english",
    },
    {
      queueName: "MMA_MBR_Spanish",
      language: "spanish",
    },
    {
      queueName: "FHK_MBR_English",
      language: "english",
    },
    {
      queueName: "FHK_MBR_Spanish",
      language: "spanish",
    },
    {
      queueName: "MMCP_MBR_English",
      language: "english",
    },
    {
      queueName: "MMCP_MBR_Spanish",
      language: "spanish",
    },
    {
      queueName: "CCP_MBR_English",
      language: "english",
    },
    {
      queueName: "CCP_MBR_Spanish",
      language: "spanish",
    },
    {
      queueName: "Testing_Queue",
      language: "english",
    },
  ];

  const getQueueDetails = (queueName) => {
    return ccpSurveyQueues.find((queue) => queue.queueName === queueName);
  };

  const closeModal = async () => {
    console.log(`[DispositionModal] =====> Closing Modal [1]`);
    if (APP_NAME === "x-ccp") {
      try {
        console.log(
          `[DispositionModal] =====> Survey Obj: ${JSON.stringify(survey)}`
        );
        const queueDetails = getQueueDetails(survey.queueName);

        console.log(
          `[DispositionModal] =====> Queue Details: ${JSON.stringify(
            queueDetails
          )}`
        );

        if (queueDetails && survey.number) {
          const surveyData = {
            //survey_studio_flow: "FWc7fb3058b439ffaa655e2add975d1a25",
            //survey_number: "+19542314408",
            to: survey.number,
            from: "+19542314408",
            agent_name: survey.agentName ? survey.agentName : "",
            queue_name: queueDetails.queueName,
            //clientId: `client:${agentName}`,
            xaqt_subclient_id: "9",
            conversation_id: survey.contactId ? survey.contactId : "XXX",
            direction: "Inbound",
            language: queueDetails.language ? queueDetails.language : "English",
          };
          const response = await sendSurvey(surveyData);
          console.log(
            `[DispositionModal] =====> Survey Response: ${JSON.stringify(
              response
            )}`
          );
        } else {
          console.log(
            "[DispositionModal] =====> Queue not found in ccpSurveyQueues. Survey not sent."
          );
        }
      } catch (err) {
        console.log(`[DispositionModal] =====> Error: ${err}`);
      }
    }

    const initialContactId = contactId;
    try {
      const res = await postContactAttributesWithCredentials(initialContactId);
      console.log(
        `Update Disposition Contact Attribute ======> ${JSON.stringify(res)}`
      );
    } catch (err) {
      console.log("err: ", err);
    }

    if (
      mainDisposition.value === "Attempting 2" ||
      mainDisposition.value === "Attempting 3" ||
      mainDisposition.value === "Attempting 4"
    ) {
      try {
        const res = await getClientSms(agentName, number);
        console.log("[getClientSms]:", res);
      } catch (err) {
        console.log("err: ", err);
      }
    }

    if (endpointId) {
      try {
        const res = await updateEndpoint(endpointId);
        console.log("[updateEndpoint]:", res);
      } catch (err) {
        console.log("err: ", err);
      }
    }

    if (reportingId) {
      try {
        await handleCreateUploadEntry();
      } catch (err) {
        console.log("err: ", err);
      }
    }

    setVisible(false);
    setMainDisposition({});
    setSelectedDisposition({});
    setDispositions([]);
  };

  //   console.log(
  //     `Received Queue Name [Disposition Modal] ======> ${JSON.stringify(survey)}`
  //   );

  return (
    <Transition.Root show={visible} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setVisible}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-900 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl ">
                <div className="flex flex-col items-center justify-center space-y-4 m-28 mx-60">
                  <div className="flex flex-row items-center justify-center px-12 py-4 bg-green-100 rounded-lg whitespace-nowrap text-md ">
                    Call Completed
                  </div>

                  <div className="flex flex-col items-center justify-center p-8 space-y-3 border rounded-lg">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-medium leading-6 text-gray-900"
                    >
                      Set Call Disposition (Required)
                    </Dialog.Title>
                    <Listbox
                      value={selectedDisposition}
                      onChange={onInputChange}
                    >
                      {({open}) => (
                        <>
                          <Listbox.Label className="block text-base font-medium text-gray-700">
                            {/* Assigned to */}
                          </Listbox.Label>

                          <Listbox.Button className="relative py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-pointer w-72 h-14 focus:border-blue-900 focus:outline-none focus:ring-1 focus:ring-blue-900 sm:text-md">
                            <span className="block truncate">
                              {selectedDisposition?.label
                                ? selectedDisposition?.label
                                : "Select a Disposition..."}
                            </span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                              <ChevronUpDownIcon
                                className="w-8 h-8 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-40 m-2 mt-1 overflow-auto text-lg bg-white border shadow-lg rounded-xl max-h-72 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-base">
                              {dispositions.map((disposition, id) => (
                                <Listbox.Option
                                  key={id}
                                  className={({active}) =>
                                    classNames(
                                      active
                                        ? "text-white bg-blue-900"
                                        : "text-gray-900",
                                      "relative cursor-pointer select-none py-2 pl-3 pr-9 text-2xl"
                                    )
                                  }
                                  value={disposition}
                                >
                                  {({selectedDisposition, active}) => (
                                    <>
                                      <span
                                        className={classNames(
                                          selectedDisposition
                                            ? "font-semibold"
                                            : "font-normal",
                                          "block truncate"
                                        )}
                                      >
                                        {disposition.label}
                                      </span>

                                      {selectedDisposition ? (
                                        <span
                                          className={classNames(
                                            active
                                              ? "text-white"
                                              : "text-blue-900",
                                            "absolute inset-y-0 right-0 flex items-center pr-4"
                                          )}
                                        >
                                          <CheckIcon
                                            className="w-5 h-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </>
                      )}
                    </Listbox>
                  </div>
                  {APP_NAME === "icx-mc" && (
                    <div className="flex flex-col items-center justify-center p-8 m-auto border rounded-lg">
                      Enter Report ID (Required)
                      <input
                        type="text"
                        className="flex px-5 py-3 mt-3 text-center border border-gray-300 rounded-md shadow-sm focus:ring-blue-900 focus:border-blue-900 sm:text-md"
                        placeholder="Enter Report ID"
                        onChange={(e) => setReportingId(e.target.value)}
                      />
                    </div>
                  )}
                  <div className="flex mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="justify-center w-32 px-4 py-2 text-base font-medium text-white bg-blue-900 border border-transparent rounded-md shadow-sm cursor-pointer h-14 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-900 focus:ring-offset-2 sm:text-md"
                      onClick={() => closeModal()}
                      disabled={
                        mainDisposition?.value === "-" && reportingId === null
                      }
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
