import React, { useCallback, useEffect } from "react";
import ConnectCCP from "./ConnectCCP";
import CustomerInfo from "./CustomerInfo";
import icxEmblem from "../assets/icx-emblem.png";
import { CONNECT_NAME } from "../config";
const Dashboard = () => {
  // Avoid re-rendering ConnectCCP

  const CCP = useCallback(() => <ConnectCCP />, []);

  return (
    <>
      <header className="m-4 border shadow-md bg-slate-800 rounded-xl">
        <nav className="px-8 lg:px-8" aria-label="Top">
          <div className="flex items-center w-full py-6 border-b border-indigo-500 lg:border-none">
            <div className="flex items-center">
              <img src={icxEmblem} alt="Xaqt Logo" className="h-12" />
            </div>
          </div>
        </nav>
      </header>
      <main className="grid grid-cols-1 gap-8 mx-12 md:grid-cols-2">
        <div className="h-full p-12 my-4 border shadow-md rounded-xl">
          <div className="h-[700px] w-[200px] flex justify-center m-auto">
            <CCP />
          </div>
        </div>
        <div className="h-full p-12 my-4 border shadow-md rounded-xl">
          <CustomerInfo />
        </div>
      </main>
    </>
  );
};

export default Dashboard;
