import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";

export default function ContactDetails({
  contactData,
  keyNameMappings,
  agentName,
}) {
  console.log("ContactDetails", agentName);
  return (
    <div className="px-4 pt-6 pb-10 border shadow-sm lg:px-8 rounded-xl">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900">
            Contact Information
          </h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
      </div>
      <div className="flow-root mt-8 -mx-6 sm:mx-0">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-6 pr-3 text-left text-base font-semibold text-gray-900 sm:pl-0"
              >
                Details
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(contactData).map((key, index) => {
              if (
                contactData[key] !== null &&
                keyNameMappings[key] !== "URL" &&
                keyNameMappings[key] !== "Endpoint ID" &&
                keyNameMappings[key] !== "Application ID"
              ) {
                return (
                  <tr key={index} className="border-b border-gray-200">
                    <td className="py-4 pl-6 pr-3 text-base sm:pl-0">
                      <div className="font-medium text-gray-900">
                        {keyNameMappings[key]}
                      </div>
                    </td>
                    <td className="px-3 py-4 text-base text-right text-gray-500 sm:table-cell">
                      {contactData[key] || "-"}
                    </td>
                  </tr>
                );
              } else if (
                contactData[key] !== null &&
                keyNameMappings[key] === "URL"
              ) {
                return (
                  <tr key={index} className="border-b border-gray-200">
                    <td className="py-4 pl-6 pr-3 text-base sm:pl-0">
                      <div className="font-medium text-gray-900">
                        {keyNameMappings[key]}
                      </div>
                    </td>
                    <td className="px-3 py-4 text-base text-right text-gray-500 sm:table-cell">
                      <a
                        href={`${contactData[key]}?agentName=${agentName}`}
                        target="_blank"
                        rel="noreferrer"
                        className="flex items-center justify-end p-2 ml-auto border shadow rounded-xl bg-gray-50 hover:bg-gray-100"
                      >
                        <p className="truncate max-w-[200px] mr-4 text-base">
                          {contactData[key] || "-"}
                        </p>

                        <ArrowTopRightOnSquareIcon className="h-8 mr-1" />
                      </a>
                    </td>
                  </tr>
                );
              }
              return null;
            })}
            {Object.keys(contactData).length === 0 && (
              <td className="py-4 pl-6 pr-3 text-base sm:pl-0">
                <div className="font-medium text-gray-900">
                  No contact information available
                </div>
              </td>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
