import React, { useEffect, useState } from "react";
import { buildGetBlueprintsUrl } from "../config";
import { genLogger, valueToOption } from "../lib";

const name = "Checklist";
const { log, error } = genLogger(name);

const getBlueprints = (queueName) => {
  const blueprintUrl = buildGetBlueprintsUrl(queueName);
  return fetch(blueprintUrl).then((res) => res.json());
};

const Checklist = ({ queueName }) => {
  const [blueprints, setBlueprints] = useState([]);

  useEffect(() => {
    let isCancelled = false;
    setBlueprints(null);
    const asyncFunc = async () => {
      try {
        log("getting blueprints for queueName");
        if (typeof queueName !== "string")
          throw new Error("queueName not a string");
        if (queueName.trim() === "") throw new Error("queueName empty");
        const response = await getBlueprints(queueName);
        let parsed = JSON.parse(response.body);
        let blueprintList = parsed.blueprints;
        const formattedBlueprints = blueprintList.map((bp) =>
          valueToOption(bp)
        );
        log("got blueprints", formattedBlueprints);
        if (!isCancelled) {
          log("setting blueprints");
          setBlueprints(formattedBlueprints);
        }
      } catch (e) {
        if (!isCancelled) error(e);
      }
    };
    if (typeof queueName === "string") asyncFunc();
    return () => (isCancelled = true);
  }, [queueName]);

  useEffect(() => {
    log("blueprints changed", blueprints);
  }, [blueprints]);

  return queueName && blueprints !== null ? (
    <div className="px-4 py-6 mt-12 border shadow-sm lg:px-8 rounded-xl">
      <div className="pb-8 sm:flex-auto">
        <h1 className="text-2xl font-semibold text-gray-900">Checklist</h1>
      </div>
      <fieldset>
        {blueprints?.map(({ value }, idx) => {
          return (
            <>
              <legend key={idx} className="sr-only">
                {value}
              </legend>
              <div className="relative flex items-center p-1">
                <div className="flex items-center h-5 m-2">
                  <input
                    id="comments"
                    aria-describedby="comments-description"
                    name="comments"
                    type="checkbox"
                    className="w-6 h-6 text-blue-900 border-gray-300 rounded "
                  />
                </div>
                <div className="ml-3 text-md">
                  <label
                    htmlFor="comments"
                    className="font-medium text-gray-700"
                  ></label>
                  <span id="comments-description" className="text-gray-500">
                    {value}
                  </span>
                </div>
              </div>
            </>
          );
        })}
      </fieldset>
    </div>
  ) : (
    <></>
  );
};

export default Checklist;
