import axios from 'axios'
const TEST_RESULTS_SMS = process.env.REACT_APP_TEST_RESULTS_SMS
const CDL_LIFE_SMS = process.env.REACT_APP_CDL_LIFE_SMS
const ALAMEDA_SMS = process.env.REACT_APP_ALAMEDA_SMS
const CLIENT = process.env.REACT_APP_CONNECT_NAME


const fairFaxText = [
    {
        text: `Do not text a reply to this message. If you have any questions about your results, please call the Fairfax County call center at 703-267-3511. Mon-Fri 9am-5pm\n\n\nNo envíe un mensaje de texto como respuesta al mensaje. Si tiene alguna pregunta sobre sus resultados, llame al centro del condado de Fairfax al 703-267-3511. Mon-Fri 9am-5pm.`,
    },
]

const cdlText = [
    {
        text: `Hi, This is <Agent Name> from CDL Life. I tried calling you regarding an application you submitted for Class A CDL training. We know it’s tough for drivers to try to find the next career move. Give us a call back at your convenience to discuss these great opportunities that we have for you. My # is (816) 653-4149. Talk to you soon.`,
    },
    {
        text: `Hi, This is <Agent Name> from CDL Life. I tried calling you regarding an application you submitted for Class A CDL careers. We know it’s tough for drivers to try to find the next career move while being out on the road. Give us a call back at your convenience to discuss these great opportunities that we have for you. My # is (816) 653-4149. Talk to you soon.`,
    },
]

const alamedaText = [
    {
        text: `The Alliance wants you to stay healthy. The COVID-19 vaccines are safe & effective. To make an appointment call 1-510-208-4829 or visit bit.ly/AlCoSignUp. Members who get the first dose by 2/28/22, can get a state-sponsored $50 gift card while they last.`,
    },
    {
        text: `La Alianza quiere que te mantengas saludable. Las vacunas COVID-19 son seguras y efectivas. Para hacer una cita llame al 1-510-208-4829 o visite bit.ly/AlCoSignUp. Los miembros que reciban la primera dosis antes del 28 de febrero de 2022 pueden obtener una Tarjeta de regalo de $50 mientras duren`,
    },
]

const test_results_sms = (agentName, to) => {
    let res;
    try {
        res = axios.post(`${TEST_RESULTS_SMS}`, {
            text: fairFaxText[0].text,
            to,
        })

    } catch (err) {
        throw err
    }
    return res
}

const cdl_life_sms = (agentName, to) => {
    let res;
    try {
        res = axios.post(`${CDL_LIFE_SMS}`, {
            text: `Hi, This is ${agentName} from CDL Life. I tried calling you regarding an application you submitted for Class A CDL training. We know it’s tough for drivers to try to find the next career move. Give us a call back at your convenience to discuss these great opportunities that we have for you. My # is (816) 281-8442. Talk to you soon.`,
            to,
        })
    } catch (err) {
        throw err
    }
    return res
}

const alameda_sms = (agentName, to) => {
    let res;
    try {
        res = axios.post(`${ALAMEDA_SMS}`, {
            text: alamedaText[0].text,
            to,
        })
    } catch (err) {
        throw err
    }
    return res
}

const getClientSms = (agentName, to) => {
    switch (CLIENT) {
        case 'x-ff':
            return test_results_sms(agentName, to)
        case 'icx-cdl':
            return cdl_life_sms(agentName, to)
        case 'icx-aah':
            return alameda_sms(agentName, to)
        default:
            return
    }
}


export default getClientSms

